import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffRolePipe } from './pipes/staff-role.pipe';
import { RoleCheckPipe } from './pipes/role-check.pipe';
import { PermissionPipe } from './pipes/permission.pipe';

@NgModule({
  declarations: [StaffRolePipe, RoleCheckPipe, PermissionPipe],
  imports: [CommonModule],
  exports: [StaffRolePipe, RoleCheckPipe, PermissionPipe]
})
export class RoleModule { }
